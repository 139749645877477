var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __commonJS = (cb, mod) => function __require() {
  return mod || (0, cb[__getOwnPropNames(cb)[0]])((mod = { exports: {} }).exports, mod), mod.exports;
};
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));

// ../types/dist/src/types/unlockTypes.js
var require_unlockTypes = __commonJS({
  "../types/dist/src/types/unlockTypes.js"(exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.KeyStatus = exports.HooksName = exports.HookType = exports.TransactionStatus = exports.TransactionType = void 0;
    var TransactionType;
    (function(TransactionType2) {
      TransactionType2["LOCK_CREATION"] = "LOCK_CREATION";
      TransactionType2["KEY_PURCHASE"] = "KEY_PURCHASE";
      TransactionType2["WITHDRAWAL"] = "WITHDRAWAL";
      TransactionType2["UPDATE_KEY_PRICE"] = "UPDATE_KEY_PRICE";
    })(TransactionType || (exports.TransactionType = TransactionType = {}));
    var TransactionStatus;
    (function(TransactionStatus2) {
      TransactionStatus2["SUBMITTED"] = "submitted";
      TransactionStatus2["PENDING"] = "pending";
      TransactionStatus2["MINED"] = "mined";
      TransactionStatus2["STALE"] = "stale";
      TransactionStatus2["FAILED"] = "failed";
      TransactionStatus2["NONE"] = "";
    })(TransactionStatus || (exports.TransactionStatus = TransactionStatus = {}));
    var HookType13;
    (function(HookType14) {
      HookType14["CUSTOM_CONTRACT"] = "CUSTOM_CONTRACT";
      HookType14["PASSWORD"] = "PASSWORD";
      HookType14["PROMOCODE"] = "PROMOCODE";
      HookType14["PROMO_CODE_CAPPED"] = "PROMO_CODE_CAPPED";
      HookType14["PASSWORD_CAPPED"] = "PASSWORD_CAPPED";
      HookType14["CAPTCHA"] = "CAPTCHA";
      HookType14["GUILD"] = "GUILD";
      HookType14["GITCOIN"] = "GITCOIN";
      HookType14["ADVANCED_TOKEN_URI"] = "ADVANCED_TOKEN_URI";
    })(HookType13 || (exports.HookType = HookType13 = {}));
    exports.HooksName = [
      "onKeyPurchaseHook",
      "onKeyCancelHook",
      "onValidKeyHook",
      "onTokenURIHook",
      "onKeyTransferHook",
      "onKeyExtendHook",
      "onKeyGrantHook"
    ];
    var KeyStatus;
    (function(KeyStatus2) {
      KeyStatus2["NONE"] = "none";
      KeyStatus2["CONFIRMING"] = "confirming";
      KeyStatus2["CONFIRMED"] = "confirmed";
      KeyStatus2["EXPIRED"] = "expired";
      KeyStatus2["VALID"] = "valid";
      KeyStatus2["SUBMITTED"] = "submitted";
      KeyStatus2["PENDING"] = "pending";
      KeyStatus2["FAILED"] = "failed";
    })(KeyStatus || (exports.KeyStatus = KeyStatus = {}));
  }
});

// ../types/dist/src/index.js
var require_src = __commonJS({
  "../types/dist/src/index.js"(exports) {
    "use strict";
    var __createBinding = exports && exports.__createBinding || (Object.create ? function(o, m, k, k2) {
      if (k2 === void 0) k2 = k;
      var desc = Object.getOwnPropertyDescriptor(m, k);
      if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
        desc = { enumerable: true, get: function() {
          return m[k];
        } };
      }
      Object.defineProperty(o, k2, desc);
    } : function(o, m, k, k2) {
      if (k2 === void 0) k2 = k;
      o[k2] = m[k];
    });
    var __exportStar = exports && exports.__exportStar || function(m, exports2) {
      for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports2, p)) __createBinding(exports2, m, p);
    };
    Object.defineProperty(exports, "__esModule", { value: true });
    __exportStar(require_unlockTypes(), exports);
  }
});

// src/networks/index.ts
var networks_exports = {};
__export(networks_exports, {
  arbitrum: () => arbitrum,
  avalanche: () => avalanche,
  base: () => base,
  baseSepolia: () => baseSepolia,
  bsc: () => bsc,
  celo: () => celo,
  gnosis: () => gnosis,
  linea: () => linea,
  mainnet: () => mainnet,
  optimism: () => optimism,
  polygon: () => polygon,
  scroll: () => scroll,
  sepolia: () => sepolia,
  zkevm: () => zkevm,
  zksync: () => zksync
});

// src/networks/mainnet.ts
var import_types = __toESM(require_src());
var mainnet = {
  blockScan: {
    url: (address) => `https://blockscan.com/address/${address}`
  },
  chain: "ethereum",
  description: "The original and most secure EVM network. ",
  explorer: {
    name: "Etherscan",
    urls: {
      address: (address) => `https://etherscan.io/address/${address}`,
      base: "https://etherscan.io/",
      token: (address, holder) => `https://etherscan.com/token/${address}?a=${holder}`,
      transaction: (hash) => `https://etherscan.io/tx/${hash}`
    }
  },
  featured: true,
  governanceBridge: {
    connext: "0x8898B472C54c31894e3B9bb83cEA802a5d0e63C6",
    domainId: 6648936,
    modules: {
      connextMod: "0xAB6A5080e569A269D8cB54fdD00312A2c4c3a3aa",
      delayMod: "0x8f05058d05C8167132f07aAA5130Ed3F0D78d5aE"
    }
  },
  hooks: {
    onKeyPurchaseHook: [
      {
        address: "0x936Ed3E71b5990bC9A94074835D08C6ca7bbFad0",
        id: import_types.HookType.PASSWORD,
        name: "Password required"
      },
      {
        address: "0xaF96721f8ffc136e4C170446E68Dc6744B6Ee4f4",
        id: import_types.HookType.CAPTCHA,
        name: "Captcha"
      },
      {
        address: "0x1d8DD27432cC0aCB93B93c4486F46b67E2208359",
        id: import_types.HookType.GUILD,
        name: "Guild"
      }
    ]
  },
  id: 1,
  isTestNetwork: false,
  keyManagerAddress: "0x9A1f43090307034DBFBE2ba20320Ce815ff046D4",
  maxFreeClaimCost: 1,
  multisig: "0x9168EABE624e9515f3836bA1716EC1DDd4C461D4",
  name: "Ethereum",
  nativeCurrency: {
    coingecko: "ethereum",
    decimals: 18,
    name: "Ether",
    symbol: "ETH",
    wrapped: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2"
  },
  opensea: {
    collectionUrl: (lockAddress) => `https://opensea.io/assets/${lockAddress}`,
    profileUrl: (address) => `https://opensea.io/${address}`,
    tokenUrl: (lockAddress, tokenId) => `https://opensea.io/assets/${lockAddress}/${tokenId}`
  },
  previousDeploys: [
    {
      startBlock: 7120795,
      unlockAddress: "0x3d5409CcE1d45233dE1D4eBDEe74b8E004abDD13"
    }
  ],
  provider: "https://rpc.unlock-protocol.com/1",
  publicLockVersionToDeploy: 14,
  publicProvider: "https://cloudflare-eth.com/v1/mainnet",
  startBlock: 16989e3,
  subgraph: {
    endpoint: "https://subgraph.unlock-protocol.com/1",
    graphId: "CTj3qyHTnQuTD16RkmV1BK6UmYPADwUD7WNNLEvQuTv9",
    studioName: "unlock-protocol-mainnet"
  },
  tokens: [
    {
      address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
      decimals: 6,
      featured: true,
      name: "USD Coin",
      symbol: "USDC"
    },
    {
      address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
      decimals: 18,
      featured: true,
      name: "Wrapped Ether",
      symbol: "WETH"
    },
    {
      address: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
      decimals: 18,
      featured: true,
      name: "Dai Stablecoin",
      symbol: "DAI"
    },
    {
      address: "0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0",
      decimals: 18,
      name: "Matic Token",
      symbol: "MATIC"
    },
    {
      address: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
      decimals: 6,
      name: "Tether USD",
      symbol: "USDT"
    },
    {
      address: "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
      decimals: 8,
      featured: true,
      name: "Wrapped BTC",
      symbol: "WBTC"
    },
    {
      address: "0x0D8775F648430679A709E98d2b0Cb6250d2887EF",
      decimals: 18,
      name: "Basic Attention Token",
      symbol: "BAT"
    },
    {
      address: "0xd7C1EB0fe4A30d3B2a846C04aa6300888f087A5F",
      decimals: 18,
      name: "POINTS",
      symbol: "POINTS"
    },
    {
      address: "0x58b6A8A3302369DAEc383334672404Ee733aB239",
      decimals: 18,
      name: "Livepeer Token",
      symbol: "LPT"
    },
    {
      address: "0x95aD61b0a150d79219dCF64E1E6Cc01f0B64C4cE",
      decimals: 18,
      name: "SHIBA INU",
      symbol: "SHIB"
    },
    {
      address: "0x514910771AF9Ca656af840dff83E8264EcF986CA",
      decimals: 18,
      name: "ChainLink Token",
      symbol: "LINK"
    },
    {
      address: "0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984",
      decimals: 18,
      name: "Uniswap",
      symbol: "UNI"
    },
    {
      address: "0xB8c77482e45F1F44dE1745F52C74426C631bDD52",
      decimals: 18,
      name: "BNB",
      symbol: "BNB"
    },
    {
      address: "0x90DE74265a416e1393A450752175AED98fe11517",
      decimals: 18,
      name: "Unlock Discount Token",
      symbol: "UDT"
    }
  ],
  uniswapV3: {
    factoryAddress: "0x1F98431c8aD98523631AE4a59f267346ea31F984",
    oracle: {
      100: "0x92C9b3A4FFD7D2046132732FedC9f9f25E316F0B",
      3e3: "0x584c5af22DB79a13F4Fb45c66E0ff2311D58d9B2",
      500: "0x2e5F6B31d100C527B782e26953D9509C591aC41d"
    },
    positionManager: "0xC36442b4a4522E871399CD717aBDD847Ab11FE88",
    quoterAddress: "0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6",
    subgraph: "https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v3",
    universalRouterAddress: "0xEf1c6E67703c7BD7107eed8303Fbe6EC2554BF6B"
  },
  unlockAddress: "0xe79B93f8E22676774F2A8dAd469175ebd00029FA",
  unlockDaoToken: {
    address: "0x90DE74265a416e1393A450752175AED98fe11517"
  },
  url: "https://ethereum.org/en/"
  // universalCard: {
  //   cardPurchaserAddress: '0x49814dd8a03594bE78a18455CC4Df3876ecFbD69',
  //   stripeDestinationNetwork: 'ethereum',
  //   stripeDestinationCurrency: 'usdc',
  // },
};

// src/networks/gnosis.ts
var import_types2 = __toESM(require_src());
var gnosis = {
  blockScan: {
    url: (address) => `https://blockscan.com/address/${address}`
  },
  chain: "xdai",
  description: "Gnosis Chain is one of the first Ethereum sidechains and has stayed true to its values.",
  explorer: {
    name: "Blockscout",
    urls: {
      address: (address) => `https://gnosisscan.io/address/${address}/transactions`,
      base: `https://gnosisscan.io/`,
      token: (address, holder) => `https://gnosisscan.io/token/${address}?a=${holder}`,
      transaction: (hash) => `https://gnosisscan.io/tx/${hash}`
    }
  },
  featured: true,
  governanceBridge: {
    connext: "0x5bB83e95f63217CDa6aE3D181BA580Ef377D2109",
    domainId: 6778479,
    modules: {
      connextMod: "0xdFB9328cF62e3525D355581dE88AeAa330879D12",
      delayMod: "0x6E74DC46EbF2cDB75B72Ab1dCAe3C98c7E9d28a1"
    }
  },
  hooks: {
    onKeyPurchaseHook: [
      {
        address: "0x927D68eAE936Ec0111f01Fc4Ddd9cC57DB3f0Af2",
        id: import_types2.HookType.PASSWORD,
        name: "Password required"
      },
      {
        address: "0xFb0657eAE55A4dd3E2317C9eCB311bA5Ecc62C9C",
        id: import_types2.HookType.CAPTCHA,
        name: "Captcha"
      },
      {
        address: "0xe20738d9798B5B5801aEEFDB81d80Fcce3a3Aa95",
        id: import_types2.HookType.GUILD,
        name: "Guild"
      },
      {
        address: "0x5ff4d5733Cc2b0A069cFF409eCEad3C5C71Ee346",
        id: import_types2.HookType.PROMO_CODE_CAPPED,
        name: "Discount code with caps"
      },
      {
        address: "0x111C61BD66a8c4f142e452b9087Dae5893C5C3cF",
        id: import_types2.HookType.PASSWORD_CAPPED,
        name: "Passwords with caps. Multiple passwords can be used per contract"
      },
      {
        address: "0xd6129cAC45a16187F4D09Dd69C512F68F0f2B371",
        id: import_types2.HookType.GITCOIN,
        name: "Gitcoin"
      }
    ],
    onTokenURIHook: [
      {
        address: "0x936Ed3E71b5990bC9A94074835D08C6ca7bbFad0",
        id: import_types2.HookType.ADVANCED_TOKEN_URI,
        name: "Advanced Token URI"
      }
    ]
  },
  id: 100,
  isTestNetwork: false,
  keyManagerAddress: "0xBa81C9379AC1221BF8C100800dD0B0b0b048ba14",
  kickbackAddress: "0x247a38358c4d99A29091C1cEadfb8a54B783D438",
  maxFreeClaimCost: 100,
  multisig: "0xfAC611a5b5a578628C28F77cEBDDB8C6159Ae79D",
  name: "Gnosis Chain",
  nativeCurrency: {
    coinbase: "DAI",
    coingecko: "xdai",
    decimals: 18,
    name: "xDAI",
    symbol: "xDAI"
  },
  previousDeploys: [
    {
      startBlock: 14521200,
      unlockAddress: "0x14bb3586Ce2946E71B95Fe00Fc73dd30ed830863"
    }
  ],
  provider: "https://rpc.unlock-protocol.com/100",
  publicLockVersionToDeploy: 14,
  publicProvider: "https://rpc.gnosischain.com",
  startBlock: 19338700,
  // This is used in llama pricing API so can't rename.
  subgraph: {
    endpoint: "https://subgraph.unlock-protocol.com/100",
    graphId: "2Pr7V4721iZj5hRgLmuganYCTRee6fqqfftLCFCd72wG",
    networkName: "gnosis",
    studioName: "unlock-protocol-gnosis"
  },
  tokens: [
    {
      address: "0xDDAfbb505ad214D7b80b1f830fcCc89B60fb7A83",
      decimals: 6,
      featured: true,
      name: "USD//C on xDai",
      symbol: "USDC"
    },
    {
      address: "0x4ECaBa5870353805a9F068101A40E0f32ed605C6",
      decimals: 6,
      featured: true,
      name: "Tether USD on xDai",
      symbol: "USDT"
    },
    {
      address: "0x8C84142c4a716a16a89d0e61707164d6107A9811",
      decimals: 18,
      name: "UDT from Ethereum",
      symbol: "UDT"
    }
  ],
  unlockAddress: "0x1bc53f4303c711cc693F6Ec3477B83703DcB317f",
  unlockDaoToken: {
    address: "0x8C84142c4a716a16a89d0e61707164d6107A9811",
    mainnetBridge: "0x41a4ee2855A7Dc328524babB07d7f505B201133e"
  },
  url: "https://www.gnosis.io/"
};

// src/networks/polygon.ts
var import_types3 = __toESM(require_src());
var polygon = {
  blockScan: {
    url: (address) => `https://blockscan.com/address/${address}`
  },
  chain: "polygon",
  description: "Polygon is a side-chain to build and scale your projects on Ethereum, the world's largest blockchain ecosystem.",
  explorer: {
    name: "Polygonscan",
    urls: {
      address: (address) => `https://polygonscan.com/address/${address}`,
      base: `https://polygonscan.com/`,
      token: (address, holder) => `https://polygonscan.com/token/${address}?a=${holder}`,
      transaction: (hash) => `https://polygonscan.com/tx/${hash}`
    }
  },
  featured: true,
  governanceBridge: {
    connext: "0x11984dc4465481512eb5b777E44061C158CF2259",
    domainId: 1886350457,
    modules: {
      connextMod: "0xa8E0FD3D023B1A253eF52B6169851Ee95eF257bE",
      delayMod: "0x31B8bB0BC5ffEe8e476202e9D97b0b8c31aA767d"
    }
  },
  hooks: {
    onKeyPurchaseHook: [
      {
        address: "0x9F4AE507d7E91Ab37CF35f792940fE079bd4E24d",
        id: import_types3.HookType.PASSWORD,
        name: "Password required"
      },
      {
        address: "0x64bDe27046F915e2BAb6339Ce4f737E34474344d",
        id: import_types3.HookType.CAPTCHA,
        name: "Captcha"
      },
      {
        address: "0xBfF080aB4600554c1e8c390d2e070CF423767B64",
        id: import_types3.HookType.GUILD,
        name: "Guild"
      },
      {
        address: "0x93E160838c529873cB7565106bBb79a3226FE07A",
        id: import_types3.HookType.PROMOCODE,
        name: "Discount code"
      },
      {
        address: "0x25Ec032F38b87295bA43C825993B9F4E1F4065c9",
        id: import_types3.HookType.PROMO_CODE_CAPPED,
        name: "Discount code with caps"
      },
      {
        address: "0xc2D767a87d74d82CD4B290a63E2D703Ff1CDf6b9",
        id: import_types3.HookType.PASSWORD_CAPPED,
        name: "Passwords with caps. Multiple passwords can be used per contract"
      }
    ]
  },
  id: 137,
  isTestNetwork: false,
  keyManagerAddress: "0x7111a1aDfbED501beaAd556Bba7cB6dCa3296aa9",
  kickbackAddress: "0x247a38358c4d99A29091C1cEadfb8a54B783D438",
  maxFreeClaimCost: 100,
  multisig: "0x479f3830fbd715342868BA95E438609BCe443DFB",
  name: "Polygon",
  nativeCurrency: {
    coingecko: "matic-network",
    decimals: 18,
    name: "Wrapped Polygon Ecosystem Token",
    symbol: "WPOL",
    wrapped: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270"
  },
  opensea: {
    collectionUrl: (lockAddress) => `https://opensea.io/assets/matic/${lockAddress}`,
    tokenUrl: (lockAddress, tokenId) => `https://opensea.io/assets/matic/${lockAddress}/${tokenId}`
  },
  previousDeploys: [
    {
      startBlock: 15714206,
      unlockAddress: "0x14bb3586Ce2946E71B95Fe00Fc73dd30ed830863"
    }
  ],
  provider: "https://rpc.unlock-protocol.com/137",
  publicLockVersionToDeploy: 14,
  publicProvider: "https://polygon-rpc.com/",
  startBlock: 21986688,
  subgraph: {
    endpoint: "https://subgraph.unlock-protocol.com/137",
    graphId: "6UrwdJt18yfk4PGzxyeYdH9hUM8PzWvuLbUTKa3T17PD",
    networkName: "matic",
    studioName: "unlock-protocol-polygon"
  },
  tokens: [
    {
      address: "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",
      decimals: 6,
      featured: true,
      name: "USD Coin",
      symbol: "USDC"
    },
    {
      address: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
      decimals: 6,
      name: "USD Coin (PoS)",
      symbol: "USDC"
    },
    {
      address: "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
      decimals: 18,
      featured: true,
      name: "Wrapped Ether",
      symbol: "WETH"
    },
    {
      address: "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
      decimals: 18,
      featured: true,
      name: "(PoS) Dai Stablecoin",
      symbol: "DAI"
    },
    {
      address: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
      decimals: 6,
      featured: true,
      name: "(PoS) Tether USD",
      symbol: "USDT"
    },
    {
      address: "0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6",
      decimals: 8,
      featured: true,
      name: "(PoS) Wrapped BTC",
      symbol: "WBTC"
    },
    {
      address: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
      decimals: 18,
      name: "Wrapped Polygon Ecosystem Token",
      symbol: "WPOL"
    },
    {
      address: "0xE06Bd4F5aAc8D0aA337D13eC88dB6defC6eAEefE",
      decimals: 18,
      name: "PlanetIX",
      symbol: "IXT"
    },
    {
      address: "0xf7E78d9C4c74df889A83C8C8d6D05BF70fF75876",
      decimals: 18,
      name: "Unlock Discount Token (PoS)",
      symbol: "UDT"
    }
  ],
  uniswapV3: {
    factoryAddress: "0x1F98431c8aD98523631AE4a59f267346ea31F984",
    oracle: {
      100: "0x8c0AC149FabEeC9b759a43fC7d301B1a1D8DE0d0",
      3e3: "0x86399725a83bB14C47bB5ce8311Ed25378BAa162",
      500: "0xfA3F427d2691ce680f96E6916a9Dac6c9042CBd2"
    },
    positionManager: "0xC36442b4a4522E871399CD717aBDD847Ab11FE88",
    quoterAddress: "0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6",
    universalRouterAddress: "0x4C60051384bd2d3C01bfc845Cf5F4b44bcbE9de5"
  },
  universalCard: {
    cardPurchaserAddress: "0xAB355a589CFbBA7a21b91E5B6063bF822dCc0016",
    stripeDestinationCurrency: "usdc",
    stripeDestinationNetwork: "polygon"
  },
  unlockAddress: "0xE8E5cd156f89F7bdB267EabD5C43Af3d5AF2A78f",
  unlockDaoToken: {
    address: "0xf7E78d9C4c74df889A83C8C8d6D05BF70fF75876",
    mainnetBridge: "0x40ec5B33f54e0E8A33A975908C5BA1c14e5BbbDf",
    uniswapV3Pool: "0x8B2b66ec1E6C1895eb408F8b8fcd8cD9510F9115"
  },
  url: "https://polygon.technology/"
};

// src/networks/bsc.ts
var import_types4 = __toESM(require_src());
var bsc = {
  blockScan: {
    url: (address) => `https://blockscan.com/address/${address}`
  },
  chain: "bsc",
  description: "The best-performing EVM compatible layer 1. Fully compatible tooling for EVM with up to 35 times of capacity.",
  explorer: {
    name: "BscScan",
    urls: {
      address: (address) => `https://bscscan.com/address/${address}`,
      base: `https://bscscan.com/`,
      token: (address, holder) => `https://bscscan.com/token/${address}?a=${holder}`,
      transaction: (hash) => `https://bscscan.com/tx/${hash}`
    }
  },
  featured: false,
  governanceBridge: {
    connext: "0xCd401c10afa37d641d2F594852DA94C700e4F2CE",
    domainId: 6450786,
    modules: {
      connextMod: "0x36b34e10295cCE69B652eEB5a8046041074515Da",
      delayMod: "0xcf07c951C44731f82E548286C7ebeC576149a49e"
    }
  },
  hooks: {
    onKeyPurchaseHook: [
      {
        address: "0x338b1f296217485bf4df6CE9f93ab4C73F72b57D",
        id: import_types4.HookType.PASSWORD,
        name: "Password required"
      },
      {
        address: "0x80E085D7591C61153D876b5171dc25756a7A3254",
        id: import_types4.HookType.CAPTCHA,
        name: "Captcha"
      },
      {
        address: "0xF6963D3c395A7914De77f771C2fC44b47B8379AC",
        id: import_types4.HookType.GUILD,
        name: "Guild"
      },
      {
        address: "0x1Bc951F8ed90F6c135F01Fe62CA348F4c3F43D00",
        id: import_types4.HookType.PROMO_CODE_CAPPED,
        name: "Discount code with caps"
      },
      {
        address: "0xa2abAeaba0ac658A1DF5517B57e45e857E3137Ad",
        id: import_types4.HookType.PASSWORD_CAPPED,
        name: "Passwords with caps. Multiple passwords can be used per contract"
      },
      {
        address: "0x5B6C5a766edBc6c7988108A689C96AfCEa95a2f1",
        id: import_types4.HookType.GITCOIN,
        name: "Gitcoin"
      }
    ],
    onTokenURIHook: [
      {
        address: "0x15922b77301Df5EA532074e9fb30d115FB6A03fE",
        id: import_types4.HookType.ADVANCED_TOKEN_URI,
        name: "Advanced Token URI"
      }
    ]
  },
  id: 56,
  isTestNetwork: false,
  keyManagerAddress: "0x34EbEc0AE80A2d078DE5489f0f5cAa4d3aaEA355",
  maxFreeClaimCost: 1,
  multisig: "0x373D7cbc4F2700719DEa237500c7a154310B0F9B",
  name: "BNB Chain",
  nativeCurrency: {
    coingecko: "binancecoin",
    decimals: 18,
    name: "BNB",
    symbol: "BNB",
    wrapped: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
  },
  opensea: {
    collectionUrl: (lockAddress) => `https://opensea.io/assets/bsc/${lockAddress}`,
    tokenUrl: (_lockAddress, _tokenId) => `https://opensea.io/assets/bsc/${_lockAddress}/${_tokenId}`
  },
  previousDeploys: [
    {
      startBlock: 12396e3,
      unlockAddress: "0x99b1348a9129ac49c6de7F11245773dE2f51fB0c"
    }
  ],
  provider: "https://rpc.unlock-protocol.com/56",
  publicLockVersionToDeploy: 14,
  publicProvider: "https://bsc-dataseed.binance.org/",
  startBlock: 13079e3,
  subgraph: {
    endpoint: "https://subgraph.unlock-protocol.com/56",
    graphId: "25Cx5DaCifBCXNVpZXMmzkafbm6KAmKqdjrPu9dT6yQp",
    studioName: "unlock-protocol-bsc"
  },
  tokens: [
    {
      address: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
      decimals: 18,
      featured: true,
      name: "USD Coin",
      symbol: "USDC"
    },
    {
      address: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
      decimals: 18,
      featured: true,
      name: "Ethereum Token",
      symbol: "ETH"
    },
    {
      address: "0x55d398326f99059fF775485246999027B3197955",
      decimals: 18,
      featured: true,
      name: "Tether USD",
      symbol: "USDT"
    },
    {
      address: "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3",
      decimals: 18,
      featured: true,
      name: "Dai Token",
      symbol: "DAI"
    },
    {
      address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      decimals: 18,
      name: "Wrapped BNB",
      symbol: "WBNB"
    }
  ],
  uniswapV3: {
    factoryAddress: "0xdB1d10011AD0Ff90774D0C6Bb92e5C5c8b4461F7",
    positionManager: "0x7b8A01B39D58278b5DE7e48c8449c9f4F5170613",
    universalRouterAddress: "0x5Dc88340E1c5c6366864Ee415d6034cadd1A9897"
  },
  unlockAddress: "0xeC83410DbC48C7797D2f2AFe624881674c65c856",
  url: "https://www.bnbchain.org/en"
};

// src/networks/optimism.ts
var import_types5 = __toESM(require_src());
var optimism = {
  blockScan: {
    url: (address) => `https://blockscan.com/address/${address}`
  },
  chain: "optimism",
  description: "Optimism is a Layer 2 Optimistic Rollup network designed to utilize the strong security guarantees of Ethereum while reducing its cost and latency.",
  explorer: {
    name: "Etherscan",
    urls: {
      address: (address) => `https://optimistic.etherscan.io/address/${address}`,
      base: `https://optimistic.etherscan.io/`,
      token: (address, holder) => `https://optimistic.etherscan.io/token/${address}?a=${holder}`,
      transaction: (hash) => `https://optimistic.etherscan.io/tx/${hash}`
    }
  },
  featured: true,
  governanceBridge: {
    connext: "0x8f7492DE823025b4CfaAB1D34c58963F2af5DEDA",
    domainId: 1869640809,
    modules: {
      connextMod: "0xF241F12506fb6Bf1909c6bC176A199166414007a",
      delayMod: "0xA8BB5AF09B599794136B14B112e137FAf83Acf1f"
    }
  },
  hooks: {
    onKeyPurchaseHook: [
      {
        address: "0x34EbEc0AE80A2d078DE5489f0f5cAa4d3aaEA355",
        id: import_types5.HookType.PASSWORD,
        name: "Password required"
      },
      {
        address: "0xF6a9138b4ebEd2AcF651Cbd40B45584B4c625e87",
        id: import_types5.HookType.CAPTCHA,
        name: "Captcha"
      },
      {
        address: "0x1402D55BF0D6566ca8F569041000a8015b608632",
        id: import_types5.HookType.GUILD,
        name: "Guild"
      },
      {
        address: "0x8e0B46ec3B95c81355175693dA0083b00fCc1326",
        id: import_types5.HookType.PROMOCODE,
        name: "Discount code"
      },
      {
        address: "0xD4385fd4A79B6636828eC8BC6795766a797E9CF5",
        id: import_types5.HookType.PROMO_CODE_CAPPED,
        name: "Discount code with caps"
      },
      {
        address: "0x7217b772788374391e890b773e6b8B7101b5Acde",
        id: import_types5.HookType.PASSWORD_CAPPED,
        name: "Passwords with caps. Multiple passwords can be used per contract"
      },
      {
        address: "0x6a0971717ABFfCfE08f67b53DeAC5D234A6676Ed",
        id: import_types5.HookType.GITCOIN,
        name: "Gitcoin"
      }
    ],
    onTokenURIHook: [
      {
        address: "0x5765883E120F707A528F3e476636304De9280b6c",
        id: import_types5.HookType.ADVANCED_TOKEN_URI,
        name: "Advanced Token URI"
      }
    ]
  },
  id: 10,
  isTestNetwork: false,
  keyManagerAddress: "0x8c5D54B2CAA4C2D08B0DDF82a1e6D2641779B8EC",
  kickbackAddress: "0x981e0Ac8ABde773a72FeD793c1BEF99a53fAC342",
  maxFreeClaimCost: 100,
  multisig: "0x6E78b4447e34e751EC181DCBed63633aA753e145",
  name: "Optimism",
  nativeCurrency: {
    coingecko: "ethereum",
    decimals: 18,
    name: "Ether",
    symbol: "ETH",
    wrapped: "0x4200000000000000000000000000000000000006"
  },
  opensea: {
    collectionUrl: (lockAddress) => `https://opensea.io/assets/optimism/${lockAddress}`,
    tokenUrl: (_lockAddress, _tokenId) => `https://opensea.io/assets/optimism/${_lockAddress}/${_tokenId}`
  },
  previousDeploys: [],
  provider: "https://rpc.unlock-protocol.com/10",
  publicLockVersionToDeploy: 14,
  publicProvider: "https://mainnet.optimism.io",
  startBlock: 302400,
  subgraph: {
    endpoint: "https://subgraph.unlock-protocol.com/10",
    graphId: "8heasZLjiLcTWtLTb7aFdWFe5yYZTgzLZfW76wrnTwrt",
    studioName: "unlock-protocol-optimism"
  },
  tokens: [
    {
      address: "0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85",
      decimals: 6,
      featured: true,
      name: "USD Coin",
      symbol: "USDC"
    },
    {
      address: "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
      decimals: 6,
      name: "USD Coin",
      symbol: "USDC"
    },
    {
      address: "0x94b008aA00579c1307B0EF2c499aD98a8ce58e58",
      decimals: 6,
      featured: true,
      name: "Tether USD",
      symbol: "USDT"
    },
    {
      address: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
      decimals: 18,
      featured: true,
      name: "Dai Stablecoin",
      symbol: "DAI"
    },
    {
      address: "0xdC6fF44d5d932Cbd77B52E5612Ba0529DC6226F1",
      decimals: 18,
      name: "Worldcoin",
      symbol: "WLD"
    },
    {
      address: "0x4200000000000000000000000000000000000042",
      decimals: 18,
      name: "Optimism",
      symbol: "OP"
    },
    {
      address: "0x4200000000000000000000000000000000000006",
      decimals: 18,
      name: "Wrapped Ether",
      symbol: "WETH"
    },
    {
      address: "0x68f180fcCe6836688e9084f035309E29Bf0A2095",
      decimals: 8,
      name: "Wrapped BTC",
      symbol: "WBTC"
    },
    {
      address: "0xc709c9116dBf29Da9c25041b13a07A0e68aC5d2D",
      decimals: 18,
      name: "Unlock Discount Token",
      symbol: "UDT"
    }
  ],
  uniswapV3: {
    factoryAddress: "0x1F98431c8aD98523631AE4a59f267346ea31F984",
    oracle: {
      100: "0xa55F8Ba16C5Bb580967f7dD94f927B21d0acF86c",
      3e3: "0x1dA6c13515362B42ACb1Ad24a713f74f925F3AEB",
      500: "0xafF14D23630d5A4BF5e36e52847bE593F0f87672"
    },
    positionManager: "0xC36442b4a4522E871399CD717aBDD847Ab11FE88",
    quoterAddress: "0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6",
    universalRouterAddress: "0xb555edF5dcF85f42cEeF1f3630a52A108E55A654"
  },
  unlockAddress: "0x99b1348a9129ac49c6de7F11245773dE2f51fB0c",
  unlockDaoToken: {
    address: "0xc709c9116dBf29Da9c25041b13a07A0e68aC5d2D",
    mainnetBridge: "0x99C9fc46f92E8a1c0deC1b1747d010903E884bE1",
    uniswapV3Pool: "0x98b506bf1916f674a7BEC5284A043a21fCC3d206"
  },
  url: "https://www.optimism.io/"
};

// src/networks/celo.ts
var import_types6 = __toESM(require_src());
var celo = {
  blockScan: {
    url: (address) => `https://blockscan.com/address/${address}`
  },
  chain: "celo",
  description: "Celo is the carbon-negative, mobile-first, EVM-compatible blockchain ecosystem leading a thriving new digital economy for all.",
  explorer: {
    name: "Celoscan",
    urls: {
      address: (address) => `https://celoscan.io/address/${address}`,
      base: `https://celoscan.io/`,
      token: (address, holder) => `https://celoscan.io/token/${address}?a=${holder}`,
      transaction: (hash) => `https://celoscan.io/tx/${hash}`
    }
  },
  featured: false,
  hooks: {
    onKeyPurchaseHook: [
      {
        address: "0xdc230F9A08918FaA5ae48B8E13647789A8B6dD46",
        id: import_types6.HookType.CAPTCHA,
        name: "Captcha"
      },
      {
        address: "0xe49f5FD63cD7ec130B07dad30f068CC08F201e1e",
        id: import_types6.HookType.GUILD,
        name: "Guild"
      },
      {
        address: "0x55C7336ea96BABb0bEabD676C07880aa8b76584a",
        id: import_types6.HookType.PROMO_CODE_CAPPED,
        name: "Discount code with caps"
      },
      {
        address: "0xC4E6FDfe46CD1DF46801e080000a4897c42Fd75F",
        id: import_types6.HookType.PASSWORD_CAPPED,
        name: "Passwords with caps. Multiple passwords can be used per contract"
      }
    ],
    onTokenURIHook: [
      {
        address: "0x15922b77301Df5EA532074e9fb30d115FB6A03fE",
        id: import_types6.HookType.ADVANCED_TOKEN_URI,
        name: "Advanced Token URI"
      }
    ]
  },
  id: 42220,
  isTestNetwork: false,
  keyManagerAddress: "0xF6963D3c395A7914De77f771C2fC44b47B8379AC",
  maxFreeClaimCost: 1,
  multisig: "0xc293E2da9E558bD8B1DFfC4a7b174729fAb2e4E8",
  name: "Celo",
  nativeCurrency: {
    coingecko: "celo",
    decimals: 18,
    name: "CELO",
    symbol: "CELO"
  },
  previousDeploys: [],
  provider: "https://rpc.unlock-protocol.com/42220",
  publicLockVersionToDeploy: 14,
  publicProvider: "https://forno.celo.org",
  startBlock: 13994123,
  subgraph: {
    endpoint: "https://subgraph.unlock-protocol.com/42220",
    graphId: "7WoP8r47dyfYvQv7tk5wxSYyszENqXz2EATjUT8x7gRm",
    studioName: "unlock-protocol-celo"
  },
  tokens: [
    {
      address: "0xef4229c8c3250C675F21BCefa42f58EfbfF6002a",
      decimals: 6,
      featured: true,
      mainnetAddress: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
      name: "USD Coin",
      symbol: "USDC"
    },
    {
      address: "0xE4fE50cdD716522A56204352f00AA110F731932d",
      decimals: 18,
      featured: true,
      mainnetAddress: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
      name: "Dai Stablecoin",
      symbol: "DAI"
    }
  ],
  uniswapV3: {
    factoryAddress: "0xAfE208a311B21f13EF87E33A90049fC17A7acDEc",
    oracle: {
      100: "0xaB82D702A4e0cD165072C005dc504A21c019718F",
      3e3: "0x5c67AD0CAfe61aF3706347aBc695D7ACcb38EFb3",
      500: "0xc9F29DdBD4D828cFb2EB491E9d48013a9c0E3C89"
    },
    quoterAddress: "0x82825d0554fA07f7FC52Ab63c961F330fdEFa8E8",
    universalRouterAddress: "0xC73d61d192FB994157168Fb56730FdEc64C9Cb8F"
  },
  unlockAddress: "0x1FF7e338d5E582138C46044dc238543Ce555C963",
  url: "https://celo.org"
};

// src/networks/arbitrum.ts
var import_types7 = __toESM(require_src());
var arbitrum = {
  blockScan: {
    url: (address) => `https://blockscan.com/address/${address}`
  },
  chain: "arbitrum",
  description: "Arbitrum One is a Layer 2 (L2) chain running on top of Ethereum Mainnet that enables high-throughput, low cost smart contracts operations.",
  explorer: {
    name: "Arbitrum",
    urls: {
      address: (address) => `https://arbiscan.io/address/${address}`,
      base: `https://arbiscan.io/`,
      token: (address, holder) => `https://arbiscan.io/token/${address}?a=${holder}`,
      transaction: (hash) => `https://arbiscan.io/tx/${hash}`
    }
  },
  featured: true,
  governanceBridge: {
    connext: "0xEE9deC2712cCE65174B561151701Bf54b99C24C8",
    domainId: 1634886255,
    modules: {
      connextMod: "0x4A553635774b3d6fB6273A83b5B49577dF450227",
      delayMod: "0xF241F12506fb6Bf1909c6bC176A199166414007a"
    }
  },
  hooks: {
    onKeyPurchaseHook: [
      {
        address: "0xd0b14797b9D08493392865647384974470202A78",
        id: import_types7.HookType.PASSWORD,
        name: "Password required"
      },
      {
        address: "0xD925Ac2887Ba4372849F0fd64217A6749552bb21",
        id: import_types7.HookType.CAPTCHA,
        name: "Captcha"
      },
      {
        address: "0x06538095ae3B5123e440D9991377B85C3BC6E6FF",
        id: import_types7.HookType.GUILD,
        name: "Guild"
      },
      {
        address: "0x1A9E2E085bF4E4fE5eE45C682a3Af26d55FA1431",
        id: import_types7.HookType.PROMO_CODE_CAPPED,
        name: "Discount code with caps"
      },
      {
        address: "0xBa81C9379AC1221BF8C100800dD0B0b0b048ba14",
        id: import_types7.HookType.PASSWORD_CAPPED,
        name: "Passwords with caps. Multiple passwords can be used per contract"
      },
      {
        address: "0x8e1158A7f9d0905602f90191AC4DFb30DD4Ee04C",
        id: import_types7.HookType.GITCOIN,
        name: "Gitcoin"
      }
    ],
    onTokenURIHook: [
      {
        address: "0xA321fEa1E5c772043a466b2969CBEB1c095Cf3BA",
        id: import_types7.HookType.ADVANCED_TOKEN_URI,
        name: "Advanced Token URI"
      }
    ]
  },
  id: 42161,
  isTestNetwork: false,
  keyManagerAddress: "0x520294E736167303efa038205D4391b58261BC9c",
  kickbackAddress: "0x62f155229fb60d793440b8c733c4C5937C626c1A",
  maxFreeClaimCost: 100,
  multisig: "0x310e9f9E3918a71dB8230cFCF32a083c7D9536d0",
  name: "Arbitrum",
  nativeCurrency: {
    coingecko: "ethereum",
    decimals: 18,
    name: "ETH",
    symbol: "ETH",
    wrapped: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1"
  },
  opensea: {
    collectionUrl: (lockAddress) => `https://opensea.io/assets/arbitrum/${lockAddress}`,
    tokenUrl: (_lockAddress, _tokenId) => `https://opensea.io/assets/arbitrum/${_lockAddress}/${_tokenId}`
  },
  previousDeploys: [],
  provider: "https://rpc.unlock-protocol.com/42161",
  publicLockVersionToDeploy: 14,
  publicProvider: "https://arb1.arbitrum.io/rpc",
  startBlock: 17429533,
  subgraph: {
    endpoint: "https://subgraph.unlock-protocol.com/42161",
    graphId: "9DNHi5TyZkxrAcnmmefRRTDHXDAwKQk7BifVY2FeTTFp",
    networkName: "arbitrum-one",
    studioName: "unlock-protocol-arbitrum"
  },
  tokens: [
    {
      address: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
      decimals: 6,
      featured: true,
      name: "USD Coin",
      symbol: "USDC"
    },
    {
      address: "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
      decimals: 6,
      name: "USD Coin (Arb1)",
      symbol: "USDC"
    },
    {
      address: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      decimals: 18,
      featured: true,
      name: "Wrapped Ether",
      symbol: "WETH"
    },
    {
      address: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
      decimals: 6,
      featured: true,
      name: "Tether USD",
      symbol: "USDT"
    },
    {
      address: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
      decimals: 18,
      featured: true,
      name: "Dai Stablecoin",
      symbol: "DAI"
    },
    {
      address: "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
      decimals: 8,
      featured: true,
      name: "Wrapped BTC",
      symbol: "WBTC"
    },
    {
      address: "0x912CE59144191C1204E64559FE8253a0e49E6548",
      decimals: 18,
      name: "Arbitrum",
      symbol: "ARB"
    },
    {
      address: "0xd5d3aA404D7562d09a848F96a8a8d5D65977bF90",
      decimals: 18,
      name: "Unlock Discount Token",
      symbol: "UDT"
    }
  ],
  uniswapV3: {
    factoryAddress: "0x1F98431c8aD98523631AE4a59f267346ea31F984",
    oracle: {
      100: "0x1dA6c13515362B42ACb1Ad24a713f74f925F3AEB",
      3e3: "0xa55F8Ba16C5Bb580967f7dD94f927B21d0acF86c",
      500: "0x2D11eEf659801cc13ADAde21667af332E77ca9AD"
    },
    positionManager: "0xC36442b4a4522E871399CD717aBDD847Ab11FE88",
    quoterAddress: "0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6",
    universalRouterAddress: "0x4C60051384bd2d3C01bfc845Cf5F4b44bcbE9de5"
  },
  unlockAddress: "0x1FF7e338d5E582138C46044dc238543Ce555C963",
  unlockDaoToken: {
    address: "0xd5d3aA404D7562d09a848F96a8a8d5D65977bF90",
    mainnetBridge: "0xa3A7B6F88361F48403514059F1F16C8E78d60EeC",
    uniswapV3Pool: "0xF0498d3BDD28fA39d248aE2c65D7F90A794Ed9fE"
  },
  url: "https://arbitrum.io/"
};

// src/networks/avalanche.ts
var import_types8 = __toESM(require_src());
var avalanche = {
  blockScan: {
    url: (address) => `https://blockscan.com/address/${address}`
  },
  chain: "avax",
  description: "Avalanche is an open, programmable smart contracts platform for decentralized applications.",
  explorer: {
    name: "Snowtrace (Avalanche)",
    urls: {
      address: (address) => `https://snowtrace.io/address/${address}`,
      base: `https://snowtrace.io/`,
      token: (address, holder) => `https://snowtrace.io/token/${address}?a=${holder}`,
      transaction: (hash) => `https://snowtrace.io/tx/${hash}`
    }
  },
  featured: false,
  hooks: {
    onKeyPurchaseHook: [
      {
        address: "0x2499D94880B30fA505543550ac8a1e24cfFeFe78",
        id: import_types8.HookType.CAPTCHA,
        name: "Captcha"
      },
      {
        address: "0x58D86Dc056c442867485941FeBeA8D3bB4657eAC",
        id: import_types8.HookType.PASSWORD,
        name: "Password required"
      },
      {
        address: "0x520294E736167303efa038205D4391b58261BC9c",
        id: import_types8.HookType.PROMO_CODE_CAPPED,
        name: "Discount code with caps"
      },
      {
        address: "0xa396CFB1fFe3ADD2308dF140aa2d0A520D5c6c31",
        id: import_types8.HookType.PASSWORD_CAPPED,
        name: "Passwords with caps. Multiple passwords can be used per contract"
      }
    ],
    onTokenURIHook: [
      {
        address: "0x927D68eAE936Ec0111f01Fc4Ddd9cC57DB3f0Af2",
        id: import_types8.HookType.ADVANCED_TOKEN_URI,
        name: "Advanced Token URI"
      }
    ]
  },
  id: 43114,
  isTestNetwork: false,
  keyManagerAddress: "0x8e0B46ec3B95c81355175693dA0083b00fCc1326",
  maxFreeClaimCost: 1,
  multisig: "0xEc7777C51327917fd2170c62873272ea168120Cb",
  name: "Avalanche (C-Chain)",
  nativeCurrency: {
    coingecko: "avalanche-2",
    decimals: 18,
    name: "AVAX",
    symbol: "AVAX"
  },
  opensea: {
    collectionUrl: (lockAddress) => `https://opensea.io/assets/avalanche/${lockAddress}`,
    tokenUrl: (_lockAddress, _tokenId) => `https://opensea.io/assets/avalanche/${_lockAddress}/${_tokenId}`
  },
  previousDeploys: [],
  provider: "https://rpc.unlock-protocol.com/43114",
  publicLockVersionToDeploy: 14,
  publicProvider: "https://api.avax.network/ext/bc/C/rpc",
  startBlock: 17188332,
  subgraph: {
    endpoint: "https://subgraph.unlock-protocol.com/43114",
    graphId: "8cLuGXsVcgmeEsaekRgKeWwF4nKahugK1VFKkTckTHmj",
    studioName: "unlock-protocol-avalanche"
  },
  tokens: [
    {
      address: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
      decimals: 6,
      featured: true,
      name: "USD Coin",
      symbol: "USDC"
    },
    {
      address: "0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB",
      decimals: 18,
      featured: true,
      name: "Wrapped Ether",
      symbol: "WETH.e"
    },
    {
      address: "0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664",
      decimals: 6,
      name: "USD Coin",
      symbol: "USDC.e"
    },
    {
      address: "0xc7198437980c041c805A1EDcbA50c1Ce5db95118",
      decimals: 6,
      featured: true,
      name: "Tether USD",
      symbol: "USDT.e"
    },
    {
      address: "0xd586E7F844cEa2F87f50152665BCbc2C279D8d70",
      decimals: 18,
      featured: true,
      name: "Dai Stablecoin",
      symbol: "DAI.e"
    },
    {
      address: "0x50b7545627a5162F82A992c33b87aDc75187B218",
      decimals: 8,
      featured: true,
      name: "Wrapped BTC",
      symbol: "WBTC.e"
    }
  ],
  uniswapV3: {
    factoryAddress: "0x740b1c1de25031C31FF4fC9A62f554A55cdC1baD",
    positionManager: "0x655C406EBFa14EE2006250925e54ec43AD184f8B",
    universalRouterAddress: "0x4Dae2f939ACf50408e13d58534Ff8c2776d45265"
  },
  unlockAddress: "0x70cBE5F72dD85aA634d07d2227a421144Af734b3",
  url: "https://www.avalabs.org/"
};

// src/networks/base.ts
var import_types9 = __toESM(require_src());
var base = {
  blockScan: {
    url: (address) => `https://blockscan.com/address/${address}`
  },
  chain: "base",
  description: "Base is a secure, low-cost, builder-friendly Ethereum L2 built to bring the next billion users onchain.  ",
  explorer: {
    name: "Basescan",
    urls: {
      address: (address) => `https://basescan.org/address/${address}`,
      base: `https://basescan.org/`,
      token: (address, holder) => `https://basescan.org/token/${address}?a=${holder}`,
      transaction: (hash) => `https://basescan.org/tx/${hash}`
    }
  },
  featured: true,
  fullySubsidizedGas: false,
  governanceBridge: {
    connext: "0xB8448C6f7f7887D36DcA487370778e419e9ebE3F",
    domainId: 1650553709,
    modules: {
      connextMod: "0xfe9fD6af67E48D9f05Aa88679Ac294E3f28532eE",
      delayMod: "0x805C2EbaE1510f59E1D717A1A51aFad335FFAec5"
    }
  },
  hooks: {
    onKeyPurchaseHook: [
      {
        address: "0x7455DdA870f8421b7C1920Efb84DFF7398c6A73E",
        id: import_types9.HookType.PASSWORD,
        name: "Password required"
      },
      {
        address: "0x8c573E1A64D7C6726B3b2E119206e9FD1f5Bc0a0",
        id: import_types9.HookType.PROMOCODE,
        name: "Discount code"
      },
      {
        address: "0xC2227b9fc792a5933E64FE9F782fdeDDaf49951b",
        id: import_types9.HookType.PROMO_CODE_CAPPED,
        name: "Discount code with caps"
      },
      {
        address: "0x87AaA7498Daf5Bb0DB03806fB5389b260E8aCe92",
        id: import_types9.HookType.GUILD,
        name: "Guild"
      },
      {
        address: "0x64441384DB40F34855b1617C05800bE43bD34709",
        id: import_types9.HookType.PASSWORD_CAPPED,
        name: "Passwords with caps. Multiple passwords can be used per contract"
      },
      {
        address: "0xbBBdD46ef548712c203d306F6587336EC15E0d7f",
        id: import_types9.HookType.GITCOIN,
        name: "Gitcoin"
      }
    ],
    onTokenURIHook: [
      {
        address: "0x16de050b14B22DC070522b8De134490CB1655B11",
        id: import_types9.HookType.ADVANCED_TOKEN_URI,
        name: "Advanced Token URI"
      }
    ]
  },
  id: 8453,
  isTestNetwork: false,
  keyManagerAddress: "0xD26c05a33349a6DeD02DD9360e1ef303d1246fb6",
  kickbackAddress: "0xCf5802682F194C2447E92a63283471A99CB792f6",
  maxFreeClaimCost: 100,
  multisig: "0x8149FeaFa41DD1ee3CA62299b9c67e9ac12FA340",
  name: "Base",
  nativeCurrency: {
    coingecko: "ethereum",
    decimals: 18,
    name: "ETH",
    symbol: "ETH",
    wrapped: "0x4200000000000000000000000000000000000006"
  },
  opensea: {
    collectionUrl: (lockAddress) => `https://opensea.io/assets/base/${lockAddress}`,
    tokenUrl: (_lockAddress, _tokenId) => `https://opensea.io/assets/base/${_lockAddress}/${_tokenId}`
  },
  previousDeploys: [],
  provider: "https://rpc.unlock-protocol.com/8453",
  publicLockVersionToDeploy: 14,
  publicProvider: "https://mainnet.base.org",
  startBlock: 175e4,
  subgraph: {
    endpoint: "https://subgraph.unlock-protocol.com/8453",
    graphId: "ECQhJQV8KWMfAAgWf8WV5duy1si9TnZpL4f194oGLrWW",
    networkName: "base",
    studioName: "unlock-protocol-base"
  },
  tokens: [
    {
      address: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
      decimals: 6,
      featured: true,
      name: "USD Coin",
      symbol: "USDC"
    },
    {
      address: "0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb",
      decimals: 18,
      featured: true,
      name: "Dai Stablecoin",
      symbol: "DAI"
    },
    {
      address: "0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA",
      decimals: 6,
      name: "USD Base Coin",
      symbol: "USDbC"
    },
    {
      address: "0x4200000000000000000000000000000000000006",
      decimals: 18,
      name: "Wrapped Ether",
      symbol: "WETH"
    },
    {
      address: "0x4ed4E862860beD51a9570b96d89aF5E1B0Efefed",
      decimals: 18,
      name: "Degen",
      symbol: "DEGEN"
    },
    {
      address: "0xaC27fa800955849d6D17cC8952Ba9dD6EAA66187",
      decimals: 18,
      name: "UnlockProtocolToken",
      symbol: "UP"
    },
    {
      address: "0xD7eA82D19f1f59FF1aE95F1945Ee6E6d86A25B96",
      decimals: 18,
      name: "Unlock Discount Token",
      symbol: "UDT"
    }
  ],
  uniswapV3: {
    factoryAddress: "0x33128a8fC17869897dcE68Ed026d694621f6FDfD",
    oracle: {
      100: "0x2411336105D4451713d23B5156038A48569EcE3a",
      3e3: "0xfa7AC1c24339f629826C419eC95961Df58563438",
      500: "0xA8BB5AF09B599794136B14B112e137FAf83Acf1f"
    },
    positionManager: "0x03a520b32C04BF3bEEf7BEb72E919cf822Ed34f1",
    universalRouterAddress: "0x198EF79F1F515F02dFE9e3115eD9fC07183f02fC"
  },
  unlockAddress: "0xd0b14797b9D08493392865647384974470202A78",
  unlockDaoToken: {
    address: "0xD7eA82D19f1f59FF1aE95F1945Ee6E6d86A25B96",
    mainnetBridge: "0x3154Cf16ccdb4C6d922629664174b904d80F2C35",
    uniswapV3Pool: "0x0a052dAd89F9695A0074958b81c85479bc8844F8"
  },
  url: "https://base.org/"
};

// src/networks/linea.ts
var import_types10 = __toESM(require_src());
var linea = {
  blockScan: {
    url: (address) => `https://blockscan.com/address/${address}`
  },
  chain: "linea",
  description: "Linea a Layer 2 zk-Rollup EVM-compatible chain powered by ConsenSys.",
  explorer: {
    name: "Linea",
    urls: {
      address: (address) => `https://lineascan.build/address/${address}`,
      base: `https://lineascan.build/`,
      token: (address, holder) => `https://lineascan.build/token/${address}?a=${holder}`,
      transaction: (hash) => `https://lineascan.build/tx/${hash}`
    }
  },
  featured: false,
  governanceBridge: {
    connext: "0xa05eF29e9aC8C75c530c2795Fa6A800e188dE0a9",
    domainId: 1818848877,
    modules: {
      connextMod: "0x1b6ED52be06Cef3b9eD234114843BE79971e51f3",
      delayMod: "0x2D4123dB5A4d3bAA2DbeA4cB10333a9E3271292a"
    }
  },
  hooks: {
    onKeyPurchaseHook: [
      {
        address: "0x6878Ae3c863f6Ebd27B47C02F6B32aAC8B0BA07E",
        id: import_types10.HookType.PASSWORD,
        name: "Password required"
      },
      {
        address: "0x8c5D54B2CAA4C2D08B0DDF82a1e6D2641779B8EC",
        id: import_types10.HookType.CAPTCHA,
        name: "Captcha"
      },
      {
        address: "0xaE8F3F0826A39122401ED634f0a5C19549331432",
        id: import_types10.HookType.GUILD,
        name: "Guild"
      },
      {
        address: "0xCD9C9b40D757b56359e19563203D3bc64089638d",
        id: import_types10.HookType.PROMOCODE,
        name: "Discount code"
      },
      {
        address: "0x04664b4290fa1F4001ED25d9576f7C2d980aC64d",
        id: import_types10.HookType.PROMO_CODE_CAPPED,
        name: "Discount code with caps"
      },
      {
        address: "0xD925Ac2887Ba4372849F0fd64217A6749552bb21",
        id: import_types10.HookType.PASSWORD_CAPPED,
        name: "Passwords with caps. Multiple passwords can be used per contract"
      }
    ]
  },
  id: 59144,
  isTestNetwork: false,
  keyManagerAddress: "0x338b1f296217485bf4df6CE9f93ab4C73F72b57D",
  maxFreeClaimCost: 10,
  multisig: "0x0b441f6A255a56670B6fdb37B527e091a394eeB9",
  name: "Linea",
  nativeCurrency: {
    coingecko: "ethereum",
    decimals: 18,
    name: "Linea Ether",
    symbol: "ETH"
  },
  previousDeploys: [],
  provider: "https://rpc.linea.build/",
  publicLockVersionToDeploy: 14,
  publicProvider: "https://rpc.linea.build/",
  startBlock: 560908,
  subgraph: {
    endpoint: "https://subgraph.unlock-protocol.com/59144",
    graphId: "3G85noAfEa4jYGPmrvzzBTY55abu2kVgXnfWwRq3absq",
    studioName: "unlock-protocol-linea"
  },
  tokens: [
    {
      address: "0x176211869cA2b568f2A7D4EE941E073a821EE1ff",
      decimals: 6,
      featured: true,
      name: "USDC",
      symbol: "USDC"
    },
    {
      address: "0xe5D7C2a44FfDDf6b295A15c148167daaAf5Cf34f",
      decimals: 18,
      featured: true,
      name: "Wrapped Ether",
      symbol: "WETH"
    },
    {
      address: "0x4AF15ec2A0BD43Db75dd04E62FAA3B8EF36b00d5",
      decimals: 18,
      featured: true,
      name: "Dai Stablecoin",
      symbol: "DAI"
    },
    {
      address: "0xA219439258ca9da29E9Cc4cE5596924745e12B93",
      decimals: 6,
      featured: true,
      name: "Tether USD",
      symbol: "USDT"
    },
    {
      address: "0x3aAB2285ddcDdaD8edf438C1bAB47e1a9D05a9b4",
      decimals: 8,
      featured: true,
      name: "Wrapped BTC",
      symbol: "WBTC"
    }
  ],
  // uniswapV3: {},
  // universalCard: {},
  unlockAddress: "0x70B3c9Dd9788570FAAb24B92c3a57d99f8186Cc7",
  url: "https://linea.build/"
};

// src/networks/sepolia.ts
var import_types11 = __toESM(require_src());
var sepolia = {
  blockScan: {
    url: (address) => `https://blockscan.com/address/${address}`
  },
  chain: "sepolia",
  description: "Sepolia is the primary testnet recommended by the Ethereum community for dapp development",
  explorer: {
    name: "Sepolia Etherscan",
    urls: {
      address: (address) => `https://sepolia.etherscan.io/address/${address}`,
      base: `https://sepolia.etherscan.io/`,
      token: (address, holder) => `https://sepolia.etherscan.io/token/${address}?a=${holder}`,
      transaction: (hash) => `https://sepolia.etherscan.io/tx/${hash}`
    }
  },
  faucets: [
    {
      name: "Google",
      url: "https://cloud.google.com/application/web3/faucet/ethereum/sepolia"
    },
    {
      name: "Alchemy",
      url: "https://www.alchemy.com/faucets/ethereum-sepolia"
    },
    {
      name: "Infura",
      url: "https://www.infura.io/faucet/sepolia"
    }
  ],
  featured: true,
  fullySubsidizedGas: true,
  hooks: {
    onKeyPurchaseHook: [
      {
        address: "0x34EbEc0AE80A2d078DE5489f0f5cAa4d3aaEA355",
        id: import_types11.HookType.PASSWORD,
        name: "Password required"
      },
      {
        address: "0xd0b14797b9D08493392865647384974470202A78",
        id: import_types11.HookType.CAPTCHA,
        name: "Captcha"
      },
      {
        address: "0x6878Ae3c863f6Ebd27B47C02F6B32aAC8B0BA07E",
        id: import_types11.HookType.GUILD,
        name: "Guild"
      },
      {
        address: "0x639143cbf90F27eA5Ce4b3A7D869d4D7878009A5",
        id: import_types11.HookType.PROMOCODE,
        name: "Discount code"
      },
      {
        address: "0x0aC1a84AcaB08b630714d59dA74576D7274E68d5",
        id: import_types11.HookType.PROMO_CODE_CAPPED,
        name: "Discount code with caps"
      },
      {
        address: "0x499C854E40Ce4056266822c86D1A326f0FE6491a",
        id: import_types11.HookType.PASSWORD_CAPPED,
        name: "Passwords with caps. Multiple passwords can be used per contract"
      },
      {
        address: "0x323Fd488F101F2F9252ecE9f9fe3e56C48912880",
        id: import_types11.HookType.GITCOIN,
        name: "Gitcoin"
      }
    ],
    onTokenURIHook: [
      {
        address: "0x7dB91c34071e7B34656DF9aCBe1542337018D617",
        id: import_types11.HookType.ADVANCED_TOKEN_URI,
        name: "Advanced Token URI"
      }
    ]
  },
  id: 11155111,
  isTestNetwork: true,
  keyManagerAddress: "0x338b1f296217485bf4df6CE9f93ab4C73F72b57D",
  kickbackAddress: "0x4D2aAeE1F34a9b4dfA57e8A4041BE82C939278dD",
  maxFreeClaimCost: 1e3,
  multisig: "0x95fE514fe7F60722AFF0FD009ebeE4Ba2013924c",
  name: "Sepolia",
  nativeCurrency: {
    coingecko: "ethereum",
    decimals: 18,
    name: "ETH",
    symbol: "ETH",
    wrapped: "0x5f207d42F869fd1c71d7f0f81a2A67Fc20FF7323"
  },
  opensea: {
    collectionUrl: (lockAddress) => `https://testnets.opensea.io/assets/sepolia/${lockAddress}`,
    tokenUrl: (_lockAddress, _tokenId) => `https://testnets.opensea.io/assets/sepolia/${_lockAddress}/${_tokenId}`
  },
  previousDeploys: [],
  provider: "https://rpc.unlock-protocol.com/11155111",
  publicLockVersionToDeploy: 14,
  publicProvider: "https://rpc2.sepolia.org/",
  startBlock: 4381710,
  subgraph: {
    endpoint: "https://subgraph.unlock-protocol.com/11155111",
    graphId: "5ZjqtfMTQJTCsHVmY9eXirW5B9SEJnRW7ipg5SzTP29k",
    networkName: "sepolia",
    studioName: "unlock-protocol-sepolia"
  },
  tokens: [
    {
      address: "0x1c7D4B196Cb0C7B01d743Fbc6116a902379C7238",
      decimals: 6,
      faucet: { name: "Circle", url: "https://faucet.circle.com/" },
      featured: true,
      name: "USDC",
      symbol: "USDC"
    },
    {
      address: "0x7b79995e5f793A07Bc00c21412e50Ecae098E7f9",
      decimals: 18,
      featured: true,
      mainnetAddress: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
      name: "Wrapped Ether",
      symbol: "WETH"
    },
    {
      address: "0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984",
      decimals: 18,
      name: "Uniswap",
      symbol: "UNI"
    },
    {
      address: "0x447B1492C5038203f1927eB2a374F5Fcdc25999d",
      decimals: 18,
      name: "Unlock Discount Token",
      symbol: "UDT"
    }
  ],
  uniswapV3: {
    factoryAddress: "0x0227628f3F023bb0B980b67D528571c95c6DaC1c",
    oracle: {
      100: "0x5D7109aF116eF9D95f107B25c401bCF3965b4027",
      3e3: "0x59E399647F12bDec93875B32376dfBcA2E69d955",
      500: "0x5Ff3C00d851daA690d5fE2a2328A81e4a8219e1a"
    },
    positionManager: "0x1238536071E1c677A632429e3655c799b22cDA52",
    universalRouterAddress: "0x3fC91A3afd70395Cd496C647d5a6CC9D4B2b7FAD"
  },
  unlockAddress: "0x36b34e10295cCE69B652eEB5a8046041074515Da",
  unlockDaoToken: {
    address: "0x447B1492C5038203f1927eB2a374F5Fcdc25999d"
  },
  url: "https://github.com/eth-clients/sepolia"
};

// src/networks/zkevm.ts
var zkevm = {
  chain: "zkevm",
  description: "Polygon zkEVM is a Layer 2 network of the Ethereum Virtual Machine (EVM), a zero-knowledge (ZK) rollup scaling solution.",
  explorer: {
    name: "zkEVM (Polygonscan)",
    urls: {
      address: (address) => `https://zkevm.polygonscan.com/address/${address}`,
      base: `https://zkevm.polygonscan.com/`,
      token: (address, holder) => `https://zkevm.polygonscan.com/token/${address}?a=${holder}`,
      transaction: (hash) => `https://zkevm.polygonscan.com/tx/${hash}`
    }
  },
  featured: false,
  hooks: {
    onKeyPurchaseHook: []
  },
  id: 1101,
  isTestNetwork: false,
  maxFreeClaimCost: 1,
  multisig: "0xD62EF39c54d9100B17c8fA3C2D15e0262338AED0",
  name: "zkEVM (Polygon)",
  nativeCurrency: {
    coingecko: "ethereum",
    decimals: 18,
    name: "ETH",
    symbol: "ETH",
    wrapped: "0x4F9A0e7FD2Bf6067db6994CF12E4495Df938E6e9"
  },
  previousDeploys: [],
  provider: "https://rpc.unlock-protocol.com/1101",
  publicLockVersionToDeploy: 14,
  publicProvider: "https://polygon-zkevm.drpc.org",
  startBlock: 0,
  subgraph: {
    endpoint: "https://subgraph.unlock-protocol.com/1101",
    graphId: "4crwbsig953XR6tpAva9PEshjM7qNujM2jDjqRX2YoWP",
    networkName: "polygon-zkevm",
    studioName: "unlock-protocol-zkevm"
  },
  tokens: [
    {
      address: "0x37eAA0eF3549a5Bb7D431be78a3D99BD360d19e5",
      decimals: 6,
      name: "USD Coin",
      symbol: "USDC"
    },
    {
      address: "0x4F9A0e7FD2Bf6067db6994CF12E4495Df938E6e9",
      decimals: 18,
      featured: true,
      name: "Wrapped Ether",
      symbol: "WETH"
    },
    {
      address: "0x1E4a5963aBFD975d8c9021ce480b42188849D41d",
      decimals: 6,
      featured: true,
      name: "Tether USD",
      symbol: "USDT"
    },
    {
      address: "0x744C5860ba161b5316F7E80D9Ec415e2727e5bD5",
      decimals: 18,
      featured: true,
      name: "Dai Stablecoin",
      symbol: "DAI"
    },
    {
      address: "0xEA034fb02eB1808C2cc3adbC15f447B93CbE08e1",
      decimals: 8,
      featured: true,
      name: "Wrapped BTC",
      symbol: "WBTC"
    }
  ],
  unlockAddress: "0x259813B665C8f6074391028ef782e27B65840d89",
  url: "https://polygon.technology/polygon-zkevm"
};

// src/networks/scroll.ts
var scroll = {
  chain: "scroll",
  description: "Scroll seamlessly extends Ethereum\u2019s capabilities through zero knowledge tech and EVM compatibility. The L2 network built by Ethereum devs for Ethereum devs.",
  explorer: {
    name: "Scrollscan",
    urls: {
      address: (address) => `https://scrollscan.com/address/${address}`,
      base: "https://scrollscan.com/",
      token: (address, holder) => `https://etherscan.com/token/${address}?a=${holder}`,
      transaction: (hash) => `https://scrollscan.com/tx/${hash}`
    }
  },
  featured: false,
  hooks: {
    onKeyPurchaseHook: []
  },
  id: 534352,
  isTestNetwork: false,
  maxFreeClaimCost: 500,
  multisig: "0x0feE9413A626a05a08AcB0E0e5D6A483e6A0a172",
  name: "Scroll",
  nativeCurrency: {
    coingecko: "ethereum",
    decimals: 18,
    name: "Ether",
    symbol: "ETH",
    wrapped: "0x5300000000000000000000000000000000000004"
  },
  provider: "https://rpc.unlock-protocol.com/534352",
  publicLockVersionToDeploy: 14,
  publicProvider: "https://rpc.scroll.io",
  startBlock: 2937779,
  subgraph: {
    endpoint: "https://subgraph.unlock-protocol.com/534352",
    graphId: "49qmkjKjv9qfvgkCQTbZcG5gYPhwybmMfRhNc8brKf4F",
    studioName: "unlock-protocol-scroll"
  },
  tokens: [
    {
      address: "0x06eFdBFf2a14a7c8E15944D1F4A48F9F95F663A4",
      decimals: 6,
      featured: true,
      name: "USD Coin",
      symbol: "USDC"
    },
    {
      address: "0x5300000000000000000000000000000000000004",
      decimals: 18,
      featured: true,
      name: "Wrapped Ether",
      symbol: "WETH"
    },
    {
      address: "0xcA77eB3fEFe3725Dc33bccB54eDEFc3D9f764f97",
      decimals: 18,
      featured: true,
      name: "Dai Stablecoin",
      symbol: "DAI"
    },
    {
      address: "0xf55BEC9cafDbE8730f096Aa55dad6D22d44099Df",
      decimals: 6,
      name: "Tether USD",
      symbol: "USDT"
    },
    {
      address: "0x3C1BCa5a656e69edCD0D4E36BEbb3FcDAcA60Cf1",
      decimals: 8,
      featured: true,
      name: "Wrapped BTC",
      symbol: "WBTC"
    }
  ],
  unlockAddress: "0x259813B665C8f6074391028ef782e27B65840d89",
  url: "https://scroll.io"
};

// src/networks/zksync.ts
var zksync = {
  blockScan: {
    url: (address) => `https://blockscan.com/address/${address}`
  },
  chain: "zksync",
  description: "zkSync is a Layer-2 protocol that scales Ethereum with cutting-edge ZK tech. Our mission is not only to merely increase Ethereum's throughput, but to fully preserve its foundational values \u2013 freedom, self-sovereignty, decentralization \u2013 at scale.",
  explorer: {
    name: "zkSync Era Block Explorer",
    urls: {
      address: (address) => `https://explorer.zksync.io/address/${address}`,
      base: `https://explorer.zksync.io/`,
      token: (address, holder) => `https://explorer.zksync.io/token/${address}?a=${holder}`,
      transaction: (hash) => `https://explorer.zksync.io/tx/${hash}`
    }
  },
  featured: false,
  hooks: {
    onKeyPurchaseHook: []
  },
  id: 324,
  isTestNetwork: false,
  maxFreeClaimCost: 1,
  multisig: "0xFa5592CE9C52FA5214ccEa10cB72Faa88eC80a3c",
  name: "zkSync Era",
  nativeCurrency: {
    coingecko: "ethereum",
    decimals: 18,
    name: "zkSync Ether",
    symbol: "ETH"
  },
  previousDeploys: [],
  provider: "https://rpc.unlock-protocol.com/324",
  publicLockVersionToDeploy: 14,
  publicProvider: "https://mainnet.era.zksync.io",
  startBlock: 25905168,
  subgraph: {
    endpoint: "https://subgraph.unlock-protocol.com/324",
    graphId: "Bqo6hTg28TRmzksb6Eg8EPefD4sXBra1ad1WD4oz6c88",
    networkName: "zksync-era",
    studioName: "unlock-protocol-zksync"
  },
  tokens: [
    {
      address: "0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4",
      decimals: 6,
      name: "Bridged USDC (zkSync)",
      symbol: "USDC.e"
    },
    {
      address: "0x000000000000000000000000000000000000800A",
      decimals: 18,
      name: "Ether",
      symbol: "ETH"
    },
    {
      address: "0x4B9eb6c0b6ea15176BBF62841C6B2A8a398cb656",
      decimals: 18,
      name: "Dai Stablecoin",
      symbol: "DAI"
    },
    {
      address: "0x493257fD37EDB34451f62EDf8D2a0C418852bA4C",
      decimals: 6,
      name: "Tether USD",
      symbol: "USDT"
    },
    {
      address: "0xBBeB516fb02a01611cBBE0453Fe3c580D7281011",
      decimals: 8,
      name: "Wrapped BTC",
      symbol: "WBTC"
    }
  ],
  unlockAddress: "0x32CF553582159F12fBb1Ae1649b3670395610F24",
  url: "https://zksync.io/"
};

// src/networks/base-sepolia.ts
var import_types12 = __toESM(require_src());
var baseSepolia = {
  blockScan: {
    url: (address) => `https://blockscan.com/address/${address}`
  },
  chain: "base-sepolia",
  description: "A public testnet for Base",
  explorer: {
    name: "Base Sepolia Etherscan",
    urls: {
      address: (address) => `https://sepolia.basescan.org/address/${address}`,
      base: `https://sepolia.basescan.org/`,
      token: (address, holder) => `https://sepolia.basescan.org/token/${address}?a=${holder}`,
      transaction: (hash) => `https://sepolia.basescan.org/tx/${hash}`
    }
  },
  faucets: [
    {
      name: "Coinbase",
      url: "https://portal.cdp.coinbase.com/products/faucet"
    },
    {
      name: "Superchain",
      url: "https://console.optimism.io/faucet"
    },
    {
      name: "Alchemy",
      url: "https://basefaucet.com/"
    }
  ],
  featured: false,
  fullySubsidizedGas: true,
  hooks: {
    onKeyPurchaseHook: [
      {
        address: "0x8c5D54B2CAA4C2D08B0DDF82a1e6D2641779B8EC",
        id: import_types12.HookType.GITCOIN,
        name: "Gitcoin"
      }
    ],
    onTokenURIHook: [
      {
        address: "0x6878Ae3c863f6Ebd27B47C02F6B32aAC8B0BA07E",
        id: import_types12.HookType.ADVANCED_TOKEN_URI,
        name: "Advanced Token URI"
      }
    ]
  },
  id: 84532,
  isTestNetwork: true,
  keyManagerAddress: "",
  kickbackAddress: "0x930730F962133216353A989d9b6cfACb19FFB49D",
  maxFreeClaimCost: 1e3,
  multisig: "0x68F2c5D9009dc4d553f814D689102a53B2b349Cc",
  name: "Base Sepolia",
  nativeCurrency: {
    coingecko: "ethereum",
    decimals: 18,
    name: "ETH",
    symbol: "ETH"
  },
  previousDeploys: [],
  provider: "https://rpc.unlock-protocol.com/84532",
  publicLockVersionToDeploy: 14,
  publicProvider: "https://sepolia.base.org",
  startBlock: 7889118,
  subgraph: {
    endpoint: "https://subgraph.unlock-protocol.com/84532",
    graphId: "FxGJ2eFse3yhWpUMrBc4VzMqAgYamn49y1JQjZNugzZf",
    networkName: "base-sepolia",
    studioName: "unlock-protocol-base-sepolia"
  },
  tokens: [
    {
      address: "0x036CbD53842c5426634e7929541eC2318f3dCF7e",
      decimals: 6,
      faucet: { name: "Circle", url: "https://faucet.circle.com/" },
      featured: true,
      name: "USDC",
      symbol: "USDC"
    },
    {
      address: "0x4200000000000000000000000000000000000006",
      decimals: 18,
      featured: true,
      mainnetAddress: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
      name: "Wrapped Ether",
      symbol: "WETH"
    }
  ],
  unlockAddress: "0x259813B665C8f6074391028ef782e27B65840d89",
  unlockDaoToken: {
    address: "0x68a8011d72E6D41bf7CE9dC49De0aeaEBAAC9b39"
  }
};

// src/index.ts
var networks = {};
Object.keys(networks_exports).forEach((networkName) => {
  const network = networks_exports[networkName];
  networks[network.id] = network;
});
var src_default = networks;
export {
  arbitrum,
  avalanche,
  base,
  baseSepolia,
  bsc,
  celo,
  src_default as default,
  gnosis,
  linea,
  mainnet,
  networks,
  optimism,
  polygon,
  scroll,
  sepolia,
  zkevm,
  zksync
};
